// import React, { useState } from "react";
// import TargetIdDocs from "./TargetIdDocs"; // Import the previously provided TargetIdDocs component
// import GettingStarted from "./GettingStarted"; // Component for AWS setup
// import TargetIdGettingStarted from "./TargetIdGettingStarted"; // Component for TargetId Getting Started
// import TargetIdTutorial from "./TargetIdTutorial"; // New component for TargetId Tutorial
// import "./Docs.css";

// const Docs = () => {
//   const [activeSection, setActiveSection] = useState("gettingStarted");

//   const renderContent = () => {
//     switch (activeSection) {
//       case "gettingStarted":
//         return (
//           <section id="gettingStarted">
//             <GettingStarted />
//           </section>
//         );
//       case "targetIdGettingStarted":
//         return (
//           <section id="targetIdGettingStarted">
//             <TargetIdGettingStarted setActiveSection={setActiveSection} />
//           </section>
//         );
//       case "targetIdHowTo":
//         return (
//           <section id="targetIdHowTo">
//             <TargetIdTutorial />
//           </section>
//         );
//       case "targetIdApiReference":
//         return <TargetIdDocs />;
//       case "perturbationGettingStarted":
//         return (
//           <section id="perturbationGettingStarted">
//             <h1>Perturbation API - Getting Started</h1>
//             <p>Step-by-step instructions to start using the Perturbation API.</p>
//           </section>
//         );
//       case "perturbationHowTo":
//         return (
//           <section id="perturbationHowTo">
//             <h1>Perturbation API - How To</h1>
//             <p>How to use Perturbation API for specific tasks.</p>
//           </section>
//         );
//       case "perturbationApiReference":
//         return (
//           <section id="perturbationApiReference">
//             <h1>Perturbation API - Reference</h1>
//             <p>Detailed API reference for the Perturbation API, including request and response formats.</p>
//           </section>
//         );
//       default:
//         return (
//           <section id="gettingStarted">
//             <GettingStarted />
//           </section>
//         );
//     }
//   };

//   return (
//     <div className="docs-container">
//       <aside className="sidebar">
//         <h3>Documentation</h3>
//         <ul>
//           <li>
//             <button onClick={() => setActiveSection("gettingStarted")}>
//               Getting Started
//             </button>
//           </li>
//           <li>
//             <button onClick={() => setActiveSection("targetIdGettingStarted")}>
//               TargetId API
//             </button>
//             {activeSection.startsWith("targetId") && (
//               <ul>
//                 <li>
//                   <button onClick={() => setActiveSection("targetIdGettingStarted")}>
//                     Getting Started
//                   </button>
//                 </li>
//                 <li>
//                   <button onClick={() => setActiveSection("targetIdApiReference")}>
//                     API Reference
//                   </button>
//                 </li>
//                 <li>
//                   <button onClick={() => setActiveSection("targetIdHowTo")}>
//                     Tutorial
//                   </button>
//                 </li>
//               </ul>
//             )}
//           </li>
//           <li>
//             <button onClick={() => setActiveSection("perturbationGettingStarted")}>
//               Perturbation API
//             </button>
//             {activeSection.startsWith("perturbation") && (
//               <ul>
//                 <li>
//                   <button onClick={() => setActiveSection("perturbationGettingStarted")}>
//                     Getting Started
//                   </button>
//                 </li>
//                 <li>
//                   <button onClick={() => setActiveSection("perturbationApiReference")}>
//                     API Reference
//                   </button>
//                 </li>
//                 <li>
//                   <button onClick={() => setActiveSection("perturbationHowTo")}>
//                     Tutorial
//                   </button>
//                 </li>
//               </ul>
//             )}
//           </li>
//         </ul>
//       </aside>
//       <main className="docs-content">
//         {renderContent()}
//       </main>
//     </div>
//   );
// };

// export default Docs;

// Docs.js
import React, { useState } from "react";
import TargetIdDocs from "./TargetIdDocs";
import GettingStarted from "./GettingStarted";
import TargetIdGettingStarted from "./TargetIdGettingStarted";
import TargetIdTutorial from "./TargetIdTutorial";
import PerturbationGettingStarted from "./PerturbationGettingStarted";
import PerturbationTutorial from "./PerturbationTutorial";
import PerturbationDocs from "./PerturbationDocs";
import ModelInformation from "./ModelInformation";
import "./Docs.css";

const Docs = () => {
  const [activeSection, setActiveSection] = useState("gettingStarted");

  const renderContent = () => {
    switch (activeSection) {
      case "gettingStarted":
        return (
          <section id="gettingStarted">
            <GettingStarted />
          </section>
        );
      case "modelInformation":
        return (
          <section id="modelInformation">
            <ModelInformation />
          </section>
        );
      case "targetIdGettingStarted":
        return (
          <section id="targetIdGettingStarted">
            <TargetIdGettingStarted setActiveSection={setActiveSection} />
          </section>
        );
      case "targetIdHowTo":
        return (
          <section id="targetIdHowTo">
            <TargetIdTutorial />
          </section>
        );
      case "targetIdApiReference":
        return <TargetIdDocs />;
      case "perturbationGettingStarted":
        return (
          <section id="perturbationGettingStarted">
            <PerturbationGettingStarted setActiveSection={setActiveSection} />
          </section>
        );
      case "perturbationHowTo":
        return (
          <section id="perturbationHowTo">
            <PerturbationTutorial />
          </section>
        );
      case "perturbationApiReference":
        return (
          <section id="perturbationApiReference">
            <PerturbationDocs />
          </section>
        );
      default:
        return (
          <section id="gettingStarted">
            <GettingStarted />
          </section>
        );
    }
  };

  return (
    <div className="docs-container">
      <aside className="sidebar">
        <h3>Documentation</h3>
        <ul>
          <li>
            <button onClick={() => setActiveSection("gettingStarted")}>
              Getting Started
            </button>
          </li>
          <li>
            <button onClick={() => setActiveSection("modelInformation")}>
              Model Information
            </button>
          </li>
          <li>
            <button onClick={() => setActiveSection("targetIdGettingStarted")}>
              Target Identification API
            </button>
            {activeSection.startsWith("targetId") && (
              <ul>
                <li>
                  <button onClick={() => setActiveSection("targetIdGettingStarted")}>
                    Getting Started
                  </button>
                </li>
                <li>
                  <button onClick={() => setActiveSection("targetIdApiReference")}>
                    API Reference
                  </button>
                </li>
                <li>
                  <button onClick={() => setActiveSection("targetIdHowTo")}>
                    Tutorial
                  </button>
                </li>
              </ul>
            )}
          </li>
          <li>
            <button onClick={() => setActiveSection("perturbationGettingStarted")}>
              Perturbation API
            </button>
            {activeSection.startsWith("perturbation") && (
              <ul>
                <li>
                  <button onClick={() => setActiveSection("perturbationGettingStarted")}>
                    Getting Started
                  </button>
                </li>
                <li>
                  <button onClick={() => setActiveSection("perturbationApiReference")}>
                    API Reference
                  </button>
                </li>
                <li>
                  <button onClick={() => setActiveSection("perturbationHowTo")}>
                    Tutorial
                  </button>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </aside>
      <main className="docs-content">
        {renderContent()}
      </main>
    </div>
  );
};

export default Docs;