import React from "react";

const ModelInformation = () => {
  return (
    <section className="api-section">
      <header className="api-header">
        <h1>Perturbation and Drug Target Models</h1>
        <p>Overview of Small Molecule, Genetic Perturbation, and Drug Target Discovery Models</p>
      </header>

      {/* Small Molecule Perturbation Model */}
      <h2>Small Molecule Perturbation Model</h2>
      <p>
        Our small molecule perturbation model is designed to predict the transcriptional response of a given cell line 
        to a chemical treatment. By integrating measurements of gene expression from both untreated (control) and treated 
        samples, the model learns how to map baseline cellular states to their perturbed counterparts under exposure to 
        a particular compound. In doing so, it captures the relationship between molecular interventions and changes in 
        gene expression profiles, thereby allowing researchers to simulate and analyze the functional impact of candidate 
        drugs without the need for lab experiments.
      </p>
      <p>
        During training, the model utilizes a dataset of cell lines that have been exposed to various small molecules, 
        as well as their corresponding unperturbed controls. By comparing the gene expression levels in the presence 
        and absence of these compounds, the model identifies key patterns of upregulation and downregulation associated 
        with drug activity. This results in a predictive framework capable of estimating the transcriptional response of 
        untested compound-cell line pairs, given only the baseline state and the SMILES encoding of a small molecule.
      </p>
      <p>
        The output of the model includes predicted gene-level expression changes compared to the expression levels in 
        the original data. The output result displays gene identifiers, baseline expression levels, expression levels 
        under treatment, and a log-transformed fold change indicating the direction and magnitude of the response to 
        the drug.
      </p>
      <br></br>
      <h3>Use Cases:</h3>
      <ul>
        <li><strong>Lead Candidate Selection:</strong> Prioritize molecules that produce desirable transcriptional signatures.</li>
        <li><strong>Drug Repositioning:</strong> Identify novel uses for known therapies.</li>
        <li><strong>Patient Stratification:</strong> Compare perturbations across patient populations using multi-omics data.</li>
        <li><strong>Clinical Trial Efficacy Prediction:</strong> Simulate likely efficacy to inform trial design.</li>
      </ul>
        <br></br>
        <br></br>
      {/* Genetic Perturbation Model */}
      <h2>Genetic Perturbation Model</h2>
      <p>
        This genetic perturbation model is designed to predict the transcriptional response of a cell line to targeted 
        genetic modifications. Instead of relying on small molecule treatments, users specify one to three genes of 
        interest along with the direction of regulation—either upregulation or downregulation—to simulate changes in 
        cellular gene expression.
      </p>
      <p>
        During training, the model utilizes a dataset of cell lines that have undergone genetic perturbations, such as 
        CRISPR knockouts or CRISPR activation for gene overexpression. Through comparisons of perturbed and unperturbed 
        states, the model identifies transcriptional fingerprints associated with the targeted genes. This predictive 
        framework helps uncover regulatory networks and anticipates changes in expression profiles.
      </p>
      <p>
        The output includes gene-level expression predictions with baseline expression levels, predicted post-perturbation 
        levels, and log-transformed fold changes to quantify the expected impact of the modifications.
      </p>
      <br></br>
      <h3>Use Cases:</h3>
      <ul>
        <li><strong>Functional Genomics and Target Validation:</strong> Assess gene manipulation impacts quickly.</li>
        <li><strong>Biomarker Discovery:</strong> Identify downstream biomarkers for diagnosis and therapeutic monitoring.</li>
        <li><strong>Clinical Trial Efficacy Prediction:</strong> Anticipate genetic synergy with existing treatments.</li>
        <li><strong>Precision Medicine:</strong> Predict personalized therapeutic strategies using patient-derived cells.</li>
      </ul>
        <br></br>
        <br></br>
      {/* Drug Target Discovery Model */}
      <h2>Drug Target Discovery Model</h2>
      <p>
        This model identifies novel drug targets based on their similarity to user-supplied “gold standard” targets. 
        Instead of predicting transcriptional changes, it generates embeddings that capture relationships between 
        candidate genes or proteins in multi-omics datasets.
      </p>
      <p>
        By learning embeddings using methods like our VGAE architecture, the model identifies patterns of co-expression, 
        functional similarity, and pathway participation. It ranks candidate targets by their similarity to validated 
        targets, helping prioritize those with therapeutic potential.
      </p>
      <p>
        The output includes a ranked list of drug targets with similarity scores to known targets.
      </p>
      <br></br>
      <h3>Use Cases:</h3>
      <ul>
        <li><strong>Drug Target Discovery:</strong> Filter large candidate target sets for therapeutic relevance.</li>
        <li><strong>Functional Screening Prioritization:</strong> Focus on promising targets with shared signatures.</li>
        <li><strong>Biomarker Identification:</strong> Reveal novel biomarkers for personalized treatment strategies.</li>
        <li><strong>Lead Optimization and Combination Therapies:</strong> Guide rational combination therapies for enhanced efficacy.</li>
      </ul>
    </section>
  );
};

export default ModelInformation;
