import React, { useState } from 'react';

const APISignupForm = ({ onGenerateAPIKey }) => {
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    email: '',
    selectedModels: [],
    useDescription: ''
  });

  const models = [
    { id: 'small_perturb', label: 'Small Molecule Perturbation' },
    { id: 'genetic_perturb', label: 'Genetic Perturbation' },
    { id: 'targetid', label: 'Target Identification' }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleModelChange = (e) => {
    const modelId = e.target.value;
    setFormData(prev => ({
      ...prev,
      selectedModels: e.target.checked
        ? [...prev.selectedModels, modelId]
        : prev.selectedModels.filter(id => id !== modelId)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    try {
        console.log(formData);
        const response = await fetch('/api/send-email', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });
   
        if (response.ok) {
            alert('Email sent successfully!');
        } else {
            const errorData = await response.json();
            console.error('Error sending email:', errorData);
            alert('Failed to send email. Please try again later.');
        }
    } catch (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
    }
   };

  return (
    <div className="max-w-md mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">API Access Signup</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block font-medium mb-2">
            Full Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            placeholder="Enter your full name"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="organization" className="block font-medium mb-2">
            Organization
          </label>
          <input
            type="text"
            id="organization"
            name="organization"
            value={formData.organization}
            onChange={handleInputChange}
            required
            placeholder="Your company or organization"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block font-medium mb-2">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            placeholder="your.email@example.com"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block font-medium mb-2">
            Select Claude Models of Interest
          </label>
          <div className="space-y-2">
            {models.map((model) => (
              <div key={model.id} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id={model.id}
                  value={model.id}
                  checked={formData.selectedModels.includes(model.id)}
                  onChange={handleModelChange}
                  className="form-checkbox text-blue-500 focus:ring-blue-500"
                />
                <label htmlFor={model.id} className="text-gray-700">
                  {model.label}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="useDescription" className="block font-medium mb-2">
            Describe Your Intended Use
          </label>
          <textarea
            id="useDescription"
            name="useDescription"
            value={formData.useDescription}
            onChange={handleInputChange}
            required
            placeholder="Explain how you plan to use Valinor's models (research, development, etc.)"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500 h-32"
          ></textarea>
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md w-full"
        >
          Request API Access
        </button>
      </form>
    </div>
  );
};

export default APISignupForm;