// PerturbationApiReference.js
import React from "react";
import "./Docs.css";

const PerturbationApiReference = () => {
  return (
    <div className="api-section">
      <header className="api-header">
        <h1>Perturbation API Documentation</h1>
        <p>
          Detailed API usage and examples for preprocessing and predicting perturbations.
        </p>
      </header>
      <main>
        <Section title="Overview">
          <p>
            The Perturbation API allows you to preprocess single-cell data and
            predict the effects of genetic or chemical perturbations. This
            documentation provides all the necessary information to use the API
            effectively.
          </p>
        </Section>

        <Section title="Create S3 Bucket and Upload Data">
          <p>
            Before submitting a job, you need an S3 bucket to store your data.
            Here is how you can create a bucket, set a bucket policy, and upload
            your data.
          </p>
          <h3>Step 1: Create an S3 Bucket</h3>
          <pre className="code-block">
            {`aws s3api create-bucket --bucket your-bucket-name --region your-region`}
          </pre>
          <h3>Step 2: Add a Bucket Policy</h3>
          <p>This policy gives the service access to your S3 bucket.</p>
          <pre className="code-block">
            {`{
    "Version": "2012-10-17",
    "Statement": [
      {
        "Sid": "AllowAccountAccess",
        "Effect": "Allow",
        "Principal": {
          "AWS": "arn:aws:iam::your-account-id:root"
        },
        "Action": "s3:*",
        "Resource": "arn:aws:s3:::your-bucket-name/*"
      }
    ]
  }`}
          </pre>
          <h3>Step 3: Upload Your Data</h3>
          <pre className="code-block">
            {`aws s3 cp your-data-file.h5ad s3://your-bucket-name/`}
          </pre>
        </Section>

        <Section title="Formatting Input Data">
          <p>
            Your input data must be in the <code>.h5ad</code> file format,
            commonly used for storing single-cell data. The data should contain
            specific columns in the observation (<code>obs</code>) and variable
            (<code>var</code>) annotations to ensure successful preprocessing
            and prediction.
          </p>

          <Section title="Required Columns in adata.obs">
            <table className="api-table">
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <code>perturbation</code>
                  </td>
                  <td>String</td>
                  <td>
                    Indicates the perturbation applied to each cell. For control
                    cells, use <code>"control"</code>.
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>cell_type</code>
                  </td>
                  <td>String</td>
                  <td>Specifies the cell type of each cell.</td>
                </tr>
                <tr>
                  <td>
                    <code>cell_line</code>
                  </td>
                  <td>String</td>
                  <td>
                    Specifies the cell line of each cell. We recommend appending
                    the naming to indicate healthy or disease states, for
                    example: <code>"astrocytes_healthy"</code>,{' '}
                    <code>"astrocytes_AD"</code>.
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <code>dose_value</code>
                  </td>
                  <td>Numeric</td>
                  <td>
                    (For drug perturbations) The dosage value applied to the
                    cells. Ensure unit consistency.
                  </td>
                </tr> */}
                {/* <tr>
                  <td>
                    <code>dose_unit</code>
                  </td>
                  <td>String</td>
                  <td>
                    (For drug perturbations) The unit of the dosage, e.g.,{' '}
                    <code>"uM"</code>.
                  </td>
                </tr> */}
              </tbody>
            </table>
            <p>
              Ensure that your <code>cell_line</code> metadata reflects the
              health status of the cells by naming them appropriately.
            </p>
          </Section>

          <Section title="Required Columns in adata.var">
            <table className="api-table">
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <code>gene_symbol</code>
                  </td>
                  <td>String</td>
                  <td>Gene symbol for each gene feature.</td>
                </tr>
                <tr>
                  <td>
                    <code>ensembl_id</code>
                  </td>
                  <td>String</td>
                  <td>Ensembl identifier for each gene feature.</td>
                </tr>
              </tbody>
            </table>
            <p>
              Ensure that your data includes these columns with accurate
              information to allow for proper preprocessing and prediction.
            </p>
          </Section>
        </Section>

        <Section title="Preprocessing Endpoint">
          <p>
            The preprocessing endpoint allows you to submit and monitor
            preprocessing jobs.
          </p>

          <Section title="Submit">
            <p>Submits a preprocessing job for processing.</p>
            <Section title="Request Syntax">
              <pre className="code-block">{`POST /perturbation/preprocess/submit`}</pre>
            </Section>
            <Section title="Request Headers">
              <table className="api-table">
                <thead>
                  <tr>
                    <th>Header</th>
                    <th>Value</th>
                    <th>Required</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>x-api-key</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>Your API key for authentication.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>Content-Type</code>
                    </td>
                    <td>application/json</td>
                    <td>Yes</td>
                    <td>Indicates the media type is JSON.</td>
                  </tr>
                </tbody>
              </table>
            </Section>
            <Section title="Request Body">
              <table className="api-table">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Type</th>
                    <th>Required</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>input_bucket_name</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>
                      Name of the S3 bucket where your input data is stored.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>input_file_name</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>File name of your input data in the S3 bucket.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>output_bucket_name</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>
                      Name of the S3 bucket where the output will be stored.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>output_file_name</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>Desired file name for the processed output data.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>perturbation_type</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>
                      Type of perturbation: <code>"gene"</code> or{' '}
                      <code>"drug"</code>.
                    </td>
                  </tr>
                </tbody>
              </table>
            </Section>
            <Section title="Response Syntax">
              <pre className="code-block">
                {`{
  "id": "job-id",
  "status": "string",
  "error": "string (if any)"
}`}
              </pre>
            </Section>
            {/* Include the rest of the API reference details here */}
          </Section>

          <Section title="Poll">
            <p>Checks the status of a submitted preprocessing job.</p>
            <Section title="Request Syntax">
              <pre className="code-block">{`GET /perturbation/preprocess/poll?job_id=your-job-id`}</pre>
            </Section>
            {/* Include additional details as needed */}
          </Section>
        </Section>

        <Section title="Prediction Endpoint">
          <p>
            The prediction endpoint allows you to submit and monitor prediction
            jobs.
          </p>

          <Section title="Submit">
            <p>Submits a prediction job for processing.</p>
            <Section title="Request Syntax">
              <pre className="code-block">{`POST /perturbation/predict/submit`}</pre>
            </Section>
            <Section title="Request Headers">
              <table className="api-table">
                <thead>
                  <tr>
                    <th>Header</th>
                    <th>Value</th>
                    <th>Required</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>x-api-key</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>Your API key for authentication.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>Content-Type</code>
                    </td>
                    <td>application/json</td>
                    <td>Yes</td>
                    <td>Indicates the media type is JSON.</td>
                  </tr>
                </tbody>
              </table>
            </Section>
            <Section title="Request Body">
              {/* Include the table and details for the request body */}
            </Section>
            <Section title="Response Syntax">
              <pre className="code-block">
                {`{
  "id": "job-id",
  "status": "string",
  "error": "string (if any)"
}`}
              </pre>
            </Section>
            {/* Include additional details as needed */}
          </Section>

          <Section title="Poll">
            <p>Checks the status of a submitted prediction job.</p>
            <Section title="Request Syntax">
              <pre className="code-block">{`GET /perturbation/predict/poll?job_id=your-job-id`}</pre>
            </Section>
            {/* Include additional details as needed */}
          </Section>
        </Section>

        {/* Add other sections like Response Elements, Error Handling, etc. */}
      </main>
    </div>
  );
};

const Section = ({ title, children }) => (
  <section className="api-section">
    <h2>{title}</h2>
    {children}
  </section>
);

export default PerturbationApiReference;